/* eslint-disable */
import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Helmet from 'react-helmet';
import queryString from 'query-string';
import {
  ItineraryDownloadPerTour,
  ItineraryDownloadPerTourAfter,
  ItineraryDownloadPerTourAfterSnow,
  ItineraryDownloadAllTours,
} from 'components';
import { FORMAT_GRAPH_DATA } from 'utilities';
import { Layout } from 'containers';
import { BASE_URL } from 'config';

const ItineraryDownload = () => (
  <StaticQuery
    query={graphql`
      query {
        allTourPages(
          filter: {
                hidden_category: { eq: false },
              }
        ) {
          edges {
            node {
              id
              api_id
              name
              absolute_slug
              itinerary_form_code
              category_ids
            }
          }
        }
      }
    `}
    render={({ allTourPages }) => {
      let title;
      let subTitle;
      let formCode;
      let afterForm = false;

      const PARSED = typeof window === 'undefined' ? {} : queryString.parse(window.location.search);
      if (PARSED.tour_id === 'all') {
        title = 'Get your brochure';
        subTitle = 'Fill in a few details and we’ll send you our brochure.';
        formCode = <ItineraryDownloadAllTours />;
      } else {
        const data = FORMAT_GRAPH_DATA(allTourPages).find(item => item.api_id === PARSED.tour_id);
        if (data) {
          title = 'Get your itinerary';
          subTitle = `Fill in a few details and we’ll send you our ${data.name} itinerary.`;
          formCode = <ItineraryDownloadPerTour tourId={PARSED.tour_id} />;
          if (PARSED.after === 'true') {
            afterForm = true;
            title = `Thanks for downloading the ${data.name} itinerary - we’ve sent it to you via email.`;
            subTitle = 'Tell Us More About Your New Zealand Trip';
            if (data.category_ids[0] === '560b2d27a6af4800ae000176') {
              formCode = <ItineraryDownloadPerTourAfterSnow tourId={PARSED.tour_id} absoluteSlug={data.absolute_slug} />;
            } else {
              formCode = <ItineraryDownloadPerTourAfter tourId={PARSED.tour_id} absoluteSlug={data.absolute_slug} />;
            }
          }
        }
      }

      if (!formCode) {
        return (
          <h1 className="c-heading c-heading--h2">Form data not found</h1>
        );
      }

      return (
        <Layout page="itinerary-download">
          <Helmet
            title="Download Itinerary"
            link={[{ rel: 'canonical', href: `${BASE_URL}/itinerary-download/` }]}
          >
            <script src="https://www.google.com/recaptcha/api.js"></script>
            <script
              dangerouslySetInnerHTML={{
                __html: `(function timestamp() { var response = document.getElementById("g-recaptcha-response"); if (response == null || response.value.trim() == "") {var elems = JSON.parse(document.getElementsByName("captcha_settings")[0].value);elems["ts"] = JSON.stringify(new Date().getTime());document.getElementsByName("captcha_settings")[0].value = JSON.stringify(elems); } } setInterval(timestamp, 500));`,
              }}
            />
          </Helmet>

          <div className="l-itinerary-download-form">
            <div className="l-container l-container--small">
              {afterForm ? (
                <div className="l-itinerary-download-form__header">
                  <p>{title}</p><br />
                  <h1 className="c-heading c-heading--h2">{subTitle}</h1>
                </div>
              ) : (
                <div className="l-itinerary-download-form__header">
                  <h1 className="c-heading c-heading--h2">{title}</h1>
                  <p>{subTitle}</p>
                </div>
              )}
              {formCode}
            </div>
          </div>
        </Layout>
      );
    }}
  />
);

export default ItineraryDownload;
